<template>
  <div>
    <Package-add-new
      :is-add-new-package-sidebar-active.sync="isAddNewPackageSidebarActive"
      @clicked="one"
      :payment-methods="paymentMethods"
      :overdue="package.monthly_charge.overdue"
      :type="'package'"
    />


    <b-modal
      id="modal-xl"
      ref="my-modal"
      no-close-on-backdrop
      ok-title="Close"
      scrollable
      centered
      button-size="sm"
      size="xl"
      title="PDF REVIEW"
    >
    <iframe :src="iframe_src" style="border:0px"  width="100%" height="600px" type="application/pdf" />
      <template #modal-footer="{ ok , closeModal  }">
        <b-button size="sm" variant="success" @click="send()" v-if=" $can('read', 'staff') ">
        Send
      </b-button>
      <b-button size="sm" variant="outline-secondary" @click="close()">
        Close
      </b-button>
    </template>
      </b-modal>


    <b-card>
      
      <b-overlay
      :show="processing"
      rounded="sm"
    >


    <b-row>
      <b-col
        cols="12"
        md="3"
        class="mb-1 mb-md-0"
      >


      <b-button size="sm" variant="flat-secondary" block class="mb-1" :to="'/apps/member/edit/'+package.token+'/'">Back</b-button>
      <b-form-group
        label="Package Name"
        label-for="Package Name"
      >
        <b-form-input
          id="package_name"
          v-model="package.package_name"
          :readonly="allow_change_name"
          trim
        />
      </b-form-group>
      <b-form-group
        label="Expire Date"
        label-for="expire-date"
      >
        <flat-pickr
          v-model="package.expire_at"
          class="form-control"
          :config="{ dateFormat: 'Y-m-d'}"
        />
      </b-form-group>
      <b-form-group
        label="Price"
        label-for="Price"
      >
        <b-form-input
          id="price"
          v-model="package.amount"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Quantity"
        label-for="Quantity"
      >
        <b-form-input
          id="quantity"
          v-model="package.quantity"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Balance"
        label-for="Balance"
      >
        <b-form-input
          id="balance"
          v-model="package.balance"
          readonly
          trim
        />
      </b-form-group>
      <b-form-group
        label="Available"
        label-for="Available"
      >
        <b-form-input
          id="available"
          v-model="package.available_book"
          readonly
          trim
        />
      </b-form-group>

      <b-form-group
        label="Status"
        label-for="Status"
      >
      <v-select
          v-model="package.status"
          :options="['ACTIVE','DISABLED']"
          :clearable="false"
          :filterable="false"
        />
      </b-form-group>
      <b-form-group
        label="Remarks"
        label-for="remarks"
      >
        <b-form-textarea
          id="remarks"
          rows="3"
          readonly
          v-model="package.remarks"
          trim
        />


      </b-form-group>
        <b-button size="sm" variant="primary" block class="mb-1" @click="save()" v-if="$can('read', 'staff')">Save</b-button>


    <hr />

     <h5>Share Member(s)</h5>
     <b-list-group>
        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(v, index) in share_member" :key="index" >
          <span>{{v.label}}</span> <font-awesome-icon style="color:red;cursor:pointer;" icon="fa-solid fa-xmark" @click="delete_share_member(index)"/>
        </b-list-group-item>
      </b-list-group>
    <b-row class="mt-2">

      <b-col cols="12">

        <v-select
          v-model="share_selected_member"
          @search="fetchOptions"
          :options="memberOptions"
          :clearable="false"
          :filterable="false"
          placeholder="Enter Member..."
          input-id="Client"
        >
              <template v-slot:spinner="{ loading }">
              <div v-show="loading">Loading...</div>
              </template>
        </v-select>

      </b-col>

      <b-col cols="12">
         <b-button size="sm" variant="primary" block class="mt-1" @click="add_share_member('add')">Add </b-button>
      </b-col>
     
      <b-col cols="12">
        <hr />
        <h5>Commissions</h5>
        <!-- <div v-for="(record, index) in package.commissions">
          {{record.display_name}}  {{record.percentage}}%
        </div> -->
        <b-list-group flush>
        <b-list-group-item class="d-flex justify-content-between align-items-center" v-for="(record, index) in package.commissions" :key="index" >
          <feather-icon icon="Trash2Icon" size="13" style="color:red;" @click="delete_commission(record.id)"/> <span><b>{{record.display_name}}</b></span> 
          
          <b-badge
            variant="primary"
            pill
            class="badge-round"
          >
          {{record.percentage}}%
          </b-badge>
          
        </b-list-group-item>
      </b-list-group>
      <b-row>
        <b-col lg="8">
          <v-select
          v-model="add_commission.user_id"
          :options="staffOptions"
          size="sm"
          :reduce="ans => ans.value" 
          :clearable="false"
          :filterable="true"
          placeholder="Enter Staff..."
        />
        </b-col>
        <b-col>
          <b-form-input v-model="add_commission.percentage"></b-form-input>
        </b-col>
        <b-col cols="12">
         <b-button size="sm" variant="primary" block class="mt-1" @click="commission_add()">Add </b-button>
      </b-col>
     
      </b-row>
      
      </b-col>
      <b-col cols="12" v-if="package.monthly_charge">
        <hr />
        <h5>Monthly Plan Setting</h5>
        <b-form-group
        label="Payment Day"
        label-for="Payment Day"
      >
        <b-form-input id="Payment Day" v-model="package.monthly_charge.payment_day" />
      </b-form-group>
      <b-form-group
        label="Quantity"
        label-for="Quantity"
      >
        <b-form-input id="quantity" v-model="package.monthly_charge.quantity" readonly/>
      </b-form-group>

      <b-form-group
        label="Calculate"
        label-for="calculate"
      >
      <b-form-input id="calculate" v-model="package.monthly_charge.calculate" readonly/>
      </b-form-group>
      <b-form-group
        label="Last Charged At"
        label-for="last_charged"
      >
        <b-form-input id="last_charged" v-model="package.monthly_charge.last_charged" readonly/>
      </b-form-group>

      <v-select
          v-model="package.monthly_charge.status"
          :options="['ACTIVE','DISABLED']"
          :clearable="false"
          :filterable="false"
        />
      <b-button size="sm" variant="primary" block class="my-1" @click="monthly_save()" v-if="$can('read', 'admin')">Save</b-button>

      <b-button size="sm" :disable="processing" variant="outline-warning" v-if="package.monthly_charge.overdue" block class="mb-1" @click="settle_overdue()">Settle Overdue - ${{package.monthly_charge.overdue}}</b-button>
      <b-button size="sm" :disable="processing" variant="outline-danger" v-if="package.monthly_charge.overdue" block class="mb-1" @click="manual_settle()">Manual Settle Overdue</b-button>
      </b-col>
    </b-row>
    </b-list-group>
    </b-col>



      <b-col
        cols="12"
        md="9"
        class="mb-1 mb-md-0 py-2"
      >
    <b-row>
      <b-col
        cols="7"
        md="7"
        class="d-flex align-items-center justify-content-start "
      >
        <b-form-group>
          <v-select
            v-model="perPage"
            label="Number of rows"
            :options="pageOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <b-col cols="2">
        <b-button size="sm" variant="outline-secondary" block class="mb-1" @click="view_agreement()">AGREEMENT</b-button>
      </b-col>
      <b-col cols="3">
        <b-button size="sm" variant="outline-secondary" block class="mb-1" @click="create_session_log()">SESSION LOG</b-button>
      </b-col>
      <b-col
        cols="12"
        md="12"
      >
      <b-table
        ref="selectableTable"
        selectable
        v-if="package.package_type == 'PACKAGE' || package.package_type == 'ACCESS'"
        :select-mode="selectMode"
        :items="items"
        :fields="fields"
        responsive
        striped
        class="mb-0"
        @row-selected="onRowSelected"
      >

        <template #cell(status_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>
      </b-table>

      <b-table
        ref="selectableTable"
        selectable
        v-if="package.package_type == 'CLASS'"
        :select-mode="selectMode"
        :items="items"
        :fields="fields_class"
        responsive
        striped
        class="mb-0"
        @row-selected="onRowSelected"
      >

        <template #cell(status_number)="data">
          <b-badge :variant="status[1][data.value]">
            {{ status[0][data.value] }}
          </b-badge>
        </template>

        <template #cell(attended)="data">
            <b-badge variant="primary"  v-if="data.item.is_attend == 'Y'">
              ATTENDED
            </b-badge>
            <b-badge variant="warning"  v-if="data.item.is_waiting_list == 'Y'">
              WAITING
            </b-badge>
        </template>
      </b-table>


      </b-col>
      <b-col
        cols="12"
        md="12"
      >
      <b-row>
        <b-col class="mt-1">
          Total : {{ totalRows }} , Display Row {{ startRow }} to {{ endRow }}
        </b-col>
        <b-col>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="right"
            size="md"
            class="my-1"
            @change="handleChangePage"
          />
        </b-col>
      </b-row>
    </b-col>
    </b-row>
    <!-- table -->
  </b-col>
</b-row>
</b-overlay>
  </b-card>
  </div>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import PackageAddNew from '../member/member-edit/SidebarOverdue.vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BTable, BAvatar, BListGroup,BOverlay,
  BListGroupItem,BCard,BModal, BCardBody, BBadge, BRow, BCol, BFormSelect, BPagination, BInputGroup,BFormTextarea, BFormInput, BInputGroupAppend, BButton, BInputGroupPrepend, BForm, BFormGroup,
} from 'bootstrap-vue'
export default {
  components: {
    BModal,
    BCard,
    PackageAddNew,
    ToastificationContent,
    BForm,
    BOverlay,
    BTable,
    flatPickr,
    BAvatar,
    BBadge,
    BRow,
    BListGroup,
    BListGroupItem,
    BFormTextarea,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BPagination,
    BCardCode,
    BTable,
    BButton,
    BFormGroup,
    BAvatar,
    BCardBody,
    BBadge,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      package:null,
      share_selected_member:null,
      processing : false,
      isAddNewPackageSidebarActive:false,
      client_module:JSON.parse(localStorage.getItem('userData')),
      memberOptions:[],
      add_commission: {
        user_id:null,
        percentage:null,
      },
      staffOptions:[],
      roleOptions: [
        { label: 'Admin', value: 'admin' },
        { label: 'Staff', value: 'staff' },
      ],
      planOptions: [
        { label: 'Basic', value: 'basic' },
        { label: 'Company', value: 'company' },
        { label: 'Enterprise', value: 'enterprise' },
        { label: 'Team', value: 'team' },
      ],
      statusOptions: [
        { label: 'Active', value: 'ACTIVE' },
        { label: 'Disabled', value: 'DISABLED' },
      ],
      centerOptions: [],
      paymentMethods:[],
      share_member: [],
      iframe_src:null,
      isAddNewStaffSidebarActive: false,
      searchString: null,
      perPage: 10,
      startRow: 1,
      endRow: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      fields: [
        { key: 'id', label: '#' },
        { key: 'date', label: 'Date' }, 
        { key: 'start', label: 'Start Time' },
        { key: 'staff_display_name', label: 'Staff' },
        { key: 'method', label: 'Method' }, 
        { key: 'created_at', label: 'Created at' }, 
        { key: 'is_attend', label: 'Attended' }
      ],
      fields_class: [
        { key: 'id', label: '#' }, 
        { key: 'class_date', label: 'Date' },  
        { key: 'class_name', label: 'Class' },
        { key: 'time', label: 'Time' }, 
        { key: 'method', label: 'Method' }, 
        { key: 'created_at', label: 'Created at' }, 
        { key: 'attended', label: 'Status' }],

      /* eslint-disable global-require */
      items: [],
      /* eslint-disable global-require */
      status: [{
        1: 'ACTIVE', 2: 'DISABLED', 3: 'Rejected', 4: 'Resigned', 5: 'Applied',
      },
      {
        1: 'light-success', 2: 'light-danger', 3: 'light-danger', 4: 'light-warning', 5: 'light-info',
      }],
      selectMode: 'single',
      selected: [],
    }
  },
  computed: {
      allow_change_name() {
        if (this.client_module.accept.includes('change_pt_package_name') === true) {
          return false
        }
        return true
      }
  },
  watch: {
    currentPage: {
      handler(newValue, oldValue) {
        this.get_data()
      },
    },
    searchString: {
      handler(newValue, oldValue) {
        // console.log("search")
        this.handleSearch()
      },
    },
    perPage: {
      handler(newValue, oldValue) {
        if (newValue < 9) {
          this.perPage = 10
        }
        // console.log("search")
        this.handleSearch()
      },
    },
  },
  created() {
    this.get_data()
    this.get_staff_list()
    this.get_payment_method()
  },
  methods: {
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    one() {
      this.isAddNewPackageSidebarActive = false
      this.get_data()
    },
    get_payment_method() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/payments/')
        .then(res => {
          this.paymentMethods = res.data
        })
    },
    send() {
      this.$http.post(process.env.VUE_APP_API_BASE+'/package/'+ router.currentRoute.params.id +'/send/',{})
        .then(res => {
          this.$refs['my-modal'].hide()
        })
    },
    get_staff_list() {
      this.$http.get(process.env.VUE_APP_API_BASE+'/staffs/select/')
        .then(res => {
          this.staffOptions = res.data
        })
    },
    commission_add() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/commission/add/`,this.add_commission )
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.add_commission = {
                percentage : null,
                user_id : null
               }
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    delete_commission(id) {
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/commission/delete/`,{id : id })
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    settle_overdue() {
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/overdue/`,{})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    manual_settle() {
      this.isAddNewPackageSidebarActive = true
      return
      this.processing = true
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/manual_overdue/`,{})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },

    create_session_log() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/package/session_log/`+ router.currentRoute.params.id + `/`)
        .then(res => {
          if (res.data.result === true) {
               this.iframe_src = '//www.cloudfit.pro/temp/SESSION_LOG_'+res.data.token+'.pdf'
               this.$refs['my-modal'].show()
             }
        })
  },
  view_agreement() {
               this.iframe_src = '//www.cloudfit.pro/pdf/AGREEMENT_'+ this.package.code +'.pdf'
               this.$refs['my-modal'].show()
  },
    add_share_member(action) {
      if (action == 'add') {
        this.share_member.push(this.share_selected_member)
      }
      let v = [];
      this.share_member.forEach((item, i) => {
        v.push(item.value)
      });
      this.share_selected_member = null
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/share_member/add/`,{"share_member":v.toString()})
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.get_data()
               this.processing = false
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
               this.processing = false
             }
        })
    },
    delete_share_member(index) {
      delete this.share_member[index]
      this.add_share_member('delete')
    },
    fetchOptions (search, loading) {
      setTimeout(() => {
      this.$http.get(process.env.VUE_APP_API_BASE+'/search/member/?string='+search)
            .then(res => {
              this.memberOptions = res.data
            })
            }, 200);
      },

    save() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/update/`,this.package)
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.password = null
               this.$refs['my-modal'].hide()
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
             }
        })
    },
    monthly_save() {
      this.$http.post(process.env.VUE_APP_API_BASE+`/package_monthly/`+ this.package.monthly_charge.id + `/update/`,this.package.monthly_charge)
        .then(res => {
          if (res.data.result === true) {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Success',
                   icon: 'CheckIcon',
                   variant: 'success',
                   text: res.data.message,
                 },
               })
               this.password = null
               this.$refs['my-modal'].hide()
             } else {
               this.$toast({
                 component: ToastificationContent,
                 position: 'top-center',
                 props: {
                   title: 'Error',
                   icon: 'XIcon',
                   variant: 'danger',
                   text: res.data.message,
                 },
               })
             }
        })
    },
    get_data() {
      this.$http.get(process.env.VUE_APP_API_BASE+`/package/`+ router.currentRoute.params.id + `/?perpage=`+this.perPage+`&page=`+this.currentPage)
        .then(res => {
          this.items = res.data.records
          this.totalRows = res.data.total
          this.startRow = res.data.startRow
          this.endRow = res.data.endRow
          this.package = res.data.package
          this.share_member = res.data.package.share_member_id
          //  console.log( res.data.members.length)
        })
    },
    close() {
      this.iframe_src = null
      this.$refs['my-modal'].hide()
    },
    handleChangePage() {

    //  this.get_data()
    },
    onRowSelected(items) {
      this.selected = items
      console.log(items)
    //  this.$router.push(`/apps/staff/edit/${items[0].user_id}/`)
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    handleSearch() {
      // let _print = `The user searched for: ${searching}\n`
      // this.$refs.pre.appendChild(document.createTextNode(_print))
    // this.searchString = searching
    // this.currentx = 1
    // this.get_data()
      this.searchTimeOut()
    },
    searchTimeOut() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }
      this.timer = setTimeout(() => {
        // your code
        this.currentPage = 1
        this.get_data()
      }, 500)
    },
  },
}
</script>

<style lang="scss">
.b-table-selectable{
  .feather{
    font-size: 1.3rem;
  }
}
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
